/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from "react";
// Then import Tailwind globally
import './src/styles/global.css';

// Add a link tag for the favicon
export const onRenderBody = ({ setHeadComponents }) => {
    setHeadComponents([
      <link
        key="favicon"
        rel="icon"
        href="/favicon.png"
        type="image/png"
      />,
    ]);
  };

